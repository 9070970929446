import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Single Arm DB Overhead Squats 3×12/arm`}</p>
    <p>{`Single Leg DB RDL’s 3×12/leg`}</p>
    <p>{`then,`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`40-DB Snatch, alternating arms (25/15)`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`30-DB Snatch, alternating arms (35/25)`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`20-DB Snatch, alternating arms (45/35)`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`10-DB Snatch, alternating arms (55/45)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      